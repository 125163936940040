<template lang="html" src="./pageSupplier.template.vue"></template>

<style lang="scss" src="./pageSupplier.scss"></style>

<script>
import {
  ApiErrorParser,
} from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageSupplier.i18n');

export default {
  name: 'PageSupplier',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      supplier: null,
      tabs: [
        {
          name: this.$t('General'),
          route: { name: 'SupplierGeneral' },
          icon: 'fas fa-globe',
        },
        {
          name: this.$t('UsersAndSecurity'),
          route: { name: 'SupplierUserAndSecurity' },
          icon: 'fas fa-shield-alt',
        },
        {
          name: this.$t('Technologies'),
          route: { name: 'SupplierTechnologies' },
          icon: 'fas fa-certificate',
        },
        {
          name: this.$t('Printers'),
          route: { name: 'SupplierPrinters' },
          icon: 'fas fa-print',
        },
        {
          name: this.$t('ExternalServices'),
          route: { name: 'SupplierExternalServices' },
          icon: 'fas fa-external-link-alt',
        },
        {
          name: this.$t('Features'),
          route: { name: 'SupplierFeaturesAndDelivery' },
          icon: 'fas fa-tasks',
        },
        {
          name: this.$t('PrivateWarehouses'),
          route: { name: 'SupplierPrivateWarehouses' },
          icon: 'fas fa-warehouse',
        },
        {
          name: this.$t('WhiteLabelConsumption'),
          route: { name: 'SupplierWhiteLabelConsumption' },
          icon: 'fas fa-euro-sign',
        },
      ],
    };
  },
  updated() {
    if(this.$route.name === 'Supplier') {
      this.$router.push({name: 'Suppliers'});
    }
  },
  created() {
    /**
     * GET SUPPLIER
     */
    this.getSupplier();
  },
  methods: {
    /**
     * GET SUPPLIER
     */
    getSupplier() {
      this.$apiInstance
        .getSupplier(this.$route.params.supplierUUID)
        .then(
          (supplierData) => {
            this.supplier = supplierData;
          },
          (error) => {
            /**
             * ERROR GET PRINTER
             */
            ApiErrorParser.parse(error);
          }
        );
    },
  }
};
</script>
